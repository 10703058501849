.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #009640;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

a, a:visited {
  color: white;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.autocomplete-dropdown-container {
  background-color: #fff;
  position: absolute!important;
  z-index: 1000;
  border-radius: 2px;
  border-top: 1px solid #d9d9d9;
  font-family: Arial, sans-serif;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  padding: 5px;
  max-width: 100%;
}

.suggestion-item, .suggestion-item--active {
  cursor: default;
  padding: 0 4px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: 30px;
  text-align: left;
  border-top: 1px solid #e6e6e6;
  font-size: 12px;
  width: 100%;
}

.suggestion-item--active {
  background-color: #fafafa;
}

input {
  width: 100%;
  padding: 0.82353rem 1.41176rem;
  font-size: 1.05882rem;
  color: #4D4D4D;
  background-color: #FFFFFF;
  border: 1px solid black;
  -webkit-appearance: none;
  border-radius: 3px;
}